/**
 * Duplicated PagePreview component but using the StaticPageLayoutShadow component.
 * This component is used to preview the static pages in the CMS.
 *
 * We should get rid of this component once we refactor the PagePreview component.
 */

import { LiveQueryProvider, useLiveQuery } from 'next-sanity/preview'
import { createContext, useEffect, useState } from 'react'

import { DefaultSEOComponent, SEOComponent } from '@/common/components/seo/seo'
import { REVIEW_LINK_TOKEN_COOKIE } from '@/common/constants/api'
import { getHtml, S3RootFolder } from '@/components/StaticPage/fetch'
import StaticPageLayoutShadow from '@/components/StaticPage/StaticPageLayout'
import { ErrorBoundary } from '@/components/wrappers/ErrorBoundary'
import { getClient } from '@/sanity/client'
import type { FooterDataSchemaType } from '@/sanity/queries/common/footerData'
import { footerData } from '@/sanity/queries/common/footerData'
import { generalSettings } from '@/sanity/queries/common/generalSettings'
import { headerData } from '@/sanity/queries/common/headerData'
import type { PageSEOFieldsExtendedType } from '@/sanity/queries/components/seo/pageSEOFields'
import type { LayoutProps } from '@/sanity/services/common/getCommonData'
import { getCookie } from '@/utils/cookies/getCookie'

import { PreviewBanner } from '../sharePreview'

export type PreviewStateType = {
  isShare: boolean
  tkn: string | undefined
}

export const PreviewContext = createContext<PreviewStateType>({
  isShare: false,
  tkn: undefined,
})

export type PreviewPageProps = {
  data: any
  token: string
  seo?: PageSEOFieldsExtendedType
  query: string
  params?: any
  Container: any
  layoutProps: LayoutProps
  isShare?: boolean
  s3RootFolder?: S3RootFolder
}

const ContainerData = ({
  data,
  seo: componentSEO,
  query,
  params = {},
  Container,
  layoutProps,
  s3RootFolder = S3RootFolder.Desktop,
}: PreviewPageProps) => {
  const { layoutData, globalSEO } = layoutProps
  const [liveData] = useLiveQuery(data, query, params) as any
  const [headerDataRes] = useLiveQuery([layoutData.headerData], headerData) as any
  const [footerDataRes] = useLiveQuery([layoutData.footerData], footerData) as any
  const [generalSettingsRes] = useLiveQuery({ globalSEO: [globalSEO] }, generalSettings) as any

  const [processedLiveData, setProcessedLiveData] = useState<
    {
      html: Awaited<ReturnType<typeof getHtml>>
    } & typeof liveData
  >()

  useEffect(() => {
    if (!liveData) return
    ;(async () => {
      if (!liveData.staticContentUrl) return
      const html = await getHtml(liveData.staticContentUrl, s3RootFolder)
      setProcessedLiveData({ ...liveData, html })
    })()
  }, [liveData, s3RootFolder])

  if (!headerDataRes || !footerDataRes || !generalSettingsRes || !liveData || !processedLiveData)
    return null

  const layoutDataLive = {
    headerData: headerDataRes.at(0),
    footerData: footerDataRes.at(0) as FooterDataSchemaType[number],
  }

  return (
    <>
      <DefaultSEOComponent globalSEO={generalSettingsRes.globalSEO.at(0)} />
      <ErrorBoundary>
        <StaticPageLayoutShadow layoutData={layoutDataLive} pageType={processedLiveData?._type}>
          {componentSEO ? <SEOComponent data={componentSEO} /> : null}
          {Container ? <Container data={processedLiveData} /> : null}
        </StaticPageLayoutShadow>
      </ErrorBoundary>
    </>
  )
}
const StaticPagePreview = (props: PreviewPageProps) => {
  const { client } = getClient({
    draftMode: true,
    draftViewToken: props.token,
    originName: 'preview-StaticPagePreview',
    filePath: __filename,
  })
  const [previewTkn, setPrevTkn] = useState()

  useEffect(() => {
    const prevToken = getCookie(REVIEW_LINK_TOKEN_COOKIE)
    setPrevTkn(prevToken)
  }, [])

  return (
    <PreviewContext.Provider value={{ isShare: !!props.isShare, tkn: previewTkn }}>
      <LiveQueryProvider client={client} logger={console}>
        <PreviewBanner
          docType={props.data?._type}
          status={props.data?.status}
          isShare={props.isShare}
        >
          <ContainerData {...props} />
        </PreviewBanner>
      </LiveQueryProvider>
    </PreviewContext.Provider>
  )
}

export default StaticPagePreview
