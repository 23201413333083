import scrollbarPlugin from 'tailwind-scrollbar'
import plugin from 'tailwindcss/plugin'

/**
 * @type {import('tailwindcss').Config}
 */
const tailwindConfig = {
  content: [
    './src/pages/**/*.{js,ts,jsx,tsx}',
    './src/components/**/*.{js,ts,jsx,tsx}',
    './src/plugins/**/*.{js,ts,jsx,tsx}',
    './src/common/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    extend: {
      colors: {
        'accent-1': '#FAFAFA',
        'accent-2': '#EAEAEA',
        'accent-7': '#333',
        success: '#0070f3',
        cyan: '#79FFE1',
        'blue-500': '#2276FC',
        'yellow-100': '#fef7da',
        black: {
          10: '#F5F5F5',
          20: '#E8E8E8',
          40: '#CDCDCD',
          50: '#979797',
          60: '#757575',
          80: '#4D4D4D',
          100: '#000000',
        },
        red: {
          100: '#C33022',
        },
        green: {
          100: '#3F5A3F',
        },
        white: {
          100: '#FFFFFF',
        },
      },
      spacing: {
        30: '7.5rem',
      },
      zIndex: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        '2k': '2000',
        '3k': '3000',
        max: '9999',
      },
      letterSpacing: {
        tighter: '-.04em',
      },
      lineHeight: {
        tight: 1.2,
      },
      fontFamily: {
        sans: ['var(--font-sans)'],
        demi: ['var(--font-demi)'],
        univers: ['var(--font-univers)'],
      },
      fontSize: {
        xs: ['0.75rem', '1.125rem'],
        sm: ['0.875rem', '1.375rem'],
        md: ['1rem', '1.5rem'],
        lg: ['1.375rem', '1.75rem'],
        xl: ['1.875rem', '2.25rem'],
        xxl: ['2.75rem', '3.25rem'],
        xxxl: ['4.125rem', '4.625rem'],
        '5xl': '2.5rem',
        '6xl': '2.75rem',
        '7xl': '4.5rem',
        '8xl': '6.25rem',
      },
      boxShadow: {
        small: '0 5px 10px rgba(0, 0, 0, 0.12)',
        medium: '0 8px 30px rgba(0, 0, 0, 0.12)',
        drop: '0px 2px 18px rgba(0, 0, 0, 0.06)',
      },
      transitionProperty: {
        'text-decoration': 'text-decoration',
      },
      screens: {
        xs: '576px',
        /*Define the medium (md) screen size as 769px.
        This setting ensures that screens up to 768px wide (inclusive) display the mobile/tablet view. */
        md: '769px',
      },
    },
  },
  future: { hoverOnlyWhenSupported: true },
  plugins: [
    scrollbarPlugin({ nocompatible: true }),
    plugin(function ({ addUtilities }) {
      addUtilities({
        '.min-app-h': {
          minHeight: 'calc(100vh - 70px - 90px)',
        },
        '.translate-50': {
          transform: 'translate(-50%, -50%)',
        },
      })
    }),
  ],
}

export default tailwindConfig
