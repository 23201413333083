import { defineConfig } from '@twind/core'
import presetAutoprefix from '@twind/preset-autoprefix'
import presetTailwind from '@twind/preset-tailwind'

import tailwindConfig from './tailwind.config'

const config = defineConfig({
  presets: [presetAutoprefix(), presetTailwind()],
  theme: tailwindConfig.theme,
})

export default config
