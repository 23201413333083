import { TO_LEARN_MORE_ABOUT_AVAILABLE_WORKS_EXTENDED } from '@/common/constants/commonCopies'
import { createInquireModalArtworkProps } from '@/components/hooks/useOpenInquiryDispatch'
import { ModalTriggerEvent, ModalTriggerTypes } from '@/events/ModalTriggerEvent'
import { ModalTypes } from '@/sanity/types'

type InquireEvent = {
  id: string
  Inquiry: any
  isOverlay: boolean
  currentArtwork?: HTMLElement
  currentCTA?: HTMLElement
}

export const openArtworkInquiry = async (e: Event) => {
  const event = e as CustomEvent<InquireEvent>

  if (!!event.detail.currentArtwork) {
    const containsText = !!event.detail.currentArtwork.textContent
    const hasPrimaryBtnClass = !!event.detail.currentArtwork?.classList.contains('primary-btn')

    if (!containsText && !hasPrimaryBtnClass) return
  }

  const data = await fetch(`/api/artwork/${event.detail.id}`).then((response) =>
    response.ok ? response.json() : null
  )

  const inquiryModalProps = data
    ? createInquireModalArtworkProps(data)
    : createInquireModalArtworkProps(data, {
        title: 'Inquire',
        subtitle: TO_LEARN_MORE_ABOUT_AVAILABLE_WORKS_EXTENDED,
      })

  window.document.dispatchEvent(
    ModalTriggerEvent({
      modalType: ModalTypes.INQUIRE,
      props: inquiryModalProps,
      triggerType: ModalTriggerTypes.CTA,
    })
  )
}
